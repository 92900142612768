html {
  font-size: 16px;
  box-sizing: border-box;
}

html, body {
  margin: 0;

  .bodywrapper {
    position: relative;
  }
}

a.link-blue, a.link-blue:visited {
  color: map_get($colors, link-blue);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
