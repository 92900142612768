.nav {
  border-left: 1px solid map-get($colors, orange);
}

aside.sidebar {
  ul.MuiList-root:first-of-type {
    padding-top: 0;
  }
  ul.MuiList-root:last-of-type {
    padding-bottom: 0;
  }
}
