form.mui {
  //font-size: 0.7rem;

  label.required:after {
    content: '*';
    color: map-get($colors, red);
    margin-left: 3px;
  }

  .MuiFormLabel-asterisk {
    color: map-get($colors, red);
  }

  .orange {
    color: map-get($colors, orange)
  }

  .MuiFormControl-root {
    p {
      margin: 0;
    }

    .MuiForm-description {
      //font-size: 0.58rem;
      //font-size: calc(0.88 * 16px);
    }
  }
}
