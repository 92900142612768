@each $name, $section in $sections {
  .section-#{map_get($section, sectionName)} {
    .mui-section-bar {
      @if map_get($section, sectionImage) == '' {
        background: #{map_get($section, sectionColor)};
      } @else {
        background: url('#{map_get($section, sectionBarImage)}');
        background-size: cover;
      }
    }
  }
  //@debug "sectionColor: #{map_get($section, sectionColor)}";
}

.mui-section-bar {
  max-height: 75px;
  height: 7vw;
  padding: 1vw 24px;
  display: flex;
  align-items: center;
  max-width: 100%;

  .page-icon {
    display: flex;
    height: 100%;
    align-items: center;
    padding-left: 0;

    img {
      width: auto;
      //height: 100%;
      height: 3vw;
      min-height: 80%;
      max-height: 100%;
      object-fit: contain;
      margin-left: -6px;
    }
  }

  .section-title {
    color: map-get($colors, white);
    font-weight: bold;
    //font-size: 1rem;
    font-size: min(calc(3vw), 20.8px); // change this for base font size change
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
