$font-folder: '../fonts/';

@include font-face('Montserrat', $font-folder + 'Montserrat/Montserrat-Black', null, null);
@include font-face('Montserrat', $font-folder + 'Montserrat/Montserrat-Bold', bold, normal);
@include font-face('Montserrat', $font-folder + 'Montserrat/Montserrat-BlackItalic', 900, italic);
@include font-face('Montserrat', $font-folder + 'Montserrat/Montserrat-Black', 900, normal);
@include font-face('Montserrat', $font-folder + 'Montserrat/Montserrat-BoldItalic', bold, italic);
@include font-face('Montserrat', $font-folder + 'Montserrat/Montserrat-ExtraBoldItalic', bold, italic);
@include font-face('Montserrat', $font-folder + 'Montserrat/Montserrat-ExtraBold', bold, normal);
@include font-face('Montserrat', $font-folder + 'Montserrat/Montserrat-ExtraLight', 200, normal);
@include font-face('Montserrat', $font-folder + 'Montserrat/Montserrat-ExtraLightItalic', 200, italic);
@include font-face('Montserrat', $font-folder + 'Montserrat/Montserrat-Italic', normal, italic);
@include font-face('Montserrat', $font-folder + 'Montserrat/Montserrat-Light', 300, normal);
@include font-face('Montserrat', $font-folder + 'Montserrat/Montserrat-LightItalic', 300, italic);
@include font-face('Montserrat', $font-folder + 'Montserrat/Montserrat-Medium', 500, normal);
@include font-face('Montserrat', $font-folder + 'Montserrat/Montserrat-Regular', normal, normal);
@include font-face('Montserrat', $font-folder + 'Montserrat/Montserrat-MediumItalic', 500, italic);
@include font-face('Montserrat', $font-folder + 'Montserrat/Montserrat-ThinItalic', 100, italic);
@include font-face('Montserrat', $font-folder + 'Montserrat/Montserrat-SemiBold', 600, normal);
@include font-face('Montserrat', $font-folder + 'Montserrat/Montserrat-SemiBoldItalic', 600, italic);
@include font-face('Montserrat', $font-folder + 'Montserrat/Montserrat-Thin', 100, normal);

@include font-face('SourceSans', $font-folder + 'SourceSans/SourceSans3-Black', null, null, ttf);
@include font-face('SourceSans', $font-folder + 'SourceSans/SourceSans3-Bold', bold, normal, ttf);
@include font-face('SourceSans', $font-folder + 'SourceSans/SourceSans3-BlackItalic', 900, italic, ttf);
@include font-face('SourceSans', $font-folder + 'SourceSans/SourceSans3-Black', 900, normal, ttf);
@include font-face('SourceSans', $font-folder + 'SourceSans/SourceSans3-BoldItalic', bold, italic, ttf);
@include font-face('SourceSans', $font-folder + 'SourceSans/SourceSans3-ExtraBoldItalic', bold, italic, ttf);
@include font-face('SourceSans', $font-folder + 'SourceSans/SourceSans3-ExtraBold', bold, normal, ttf);
@include font-face('SourceSans', $font-folder + 'SourceSans/SourceSans3-ExtraLight', 200, normal, ttf);
@include font-face('SourceSans', $font-folder + 'SourceSans/SourceSans3-ExtraLightItalic', 200, italic, ttf);
@include font-face('SourceSans', $font-folder + 'SourceSans/SourceSans3-Italic', normal, italic, ttf);
@include font-face('SourceSans', $font-folder + 'SourceSans/SourceSans3-Light', 300, normal, ttf);
@include font-face('SourceSans', $font-folder + 'SourceSans/SourceSans3-LightItalic', 300, italic, ttf);
@include font-face('SourceSans', $font-folder + 'SourceSans/SourceSans3-Medium', 500, normal, ttf);
@include font-face('SourceSans', $font-folder + 'SourceSans/SourceSans3-Regular', normal, normal, ttf);
@include font-face('SourceSans', $font-folder + 'SourceSans/SourceSans3-MediumItalic', 500, italic, ttf);
@include font-face('SourceSans', $font-folder + 'SourceSans/SourceSans3-SemiBold', 600, normal, ttf);
@include font-face('SourceSans', $font-folder + 'SourceSans/SourceSans3-SemiBoldItalic', 600, italic, ttf);
